import {
  browser,
  deviceTypes,
  pageCluster,
  rumArray,
  userTimingArray,
} from "./filter-list";

/**
 *
 */
export const metricPrefix = "rum_";

export enum METRIC_TYPE {
  rum,
  userTiming,
}

/*               */
export type RawUserTimingData = Record<string, number>;

/*                                                                 */
export type RawRumData = Record<string, string | number | boolean>;

export interface IMetric {
  metricName: string;
  value: number;
}

/*                                                                 */
export interface RumData {
  deviceBrowser: string;
  deviceOS: string;
  pageCluster: string;
  rum_deviceOrientation: string;
  rum_deviceType: string;
  rum_domComplete: number;
  beaconType: string;
  rumMetrics: IMetric[];
  requestTime: number;
}

/*                                                                        */
export interface UserTimingData {
  rumMetrics: IMetric[];
  deviceBrowser: string;
  deviceOS: string;
  rum_deviceType: string;
  rum_deviceOrientation: string;
  rum_supportsTouch: boolean;
  pageCluster: string;
  beaconType: string;
  requestTime: number;
}

/**
 *
 *
 */
export function isEmptyBeacon(
  data: RumData | UserTimingData | RawRumData,
): boolean {
  return !data || Object.keys(data).length === 0;
}

/**
 *
 */
export function isToggleActive(toggleName: string): boolean {
  return window.o_util.toggle.get(toggleName, false);
}

/**
 *
 */
export function readBeaconServicePath(): string {
  return "/quality-beacon-service/";
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function sendValidBeacon(
  dataObject: RumData | UserTimingData,
  endpoint: string,
  toggleName: string,
  metricType: METRIC_TYPE,
): void {
  if (
    isEmptyBeacon(dataObject) ||
    !isToggleActive(toggleName) ||
    !isRequiredDataCorrectlySet(dataObject, metricType)
  ) {
    return;
  }
  navigator.sendBeacon(endpoint, JSON.stringify(dataObject));
}

/**
 *
 */
export function capitalizeFirstLetterAfterPrefix(given: string): string {
  let idx = 0;
  let prefix = "";
  if (given.startsWith(metricPrefix)) {
    idx = metricPrefix.length;
    prefix = metricPrefix;
  }
  return prefix + capitalizeFirstLetter(given.slice(idx));
}

function capitalizeFirstLetter(given: string): string {
  return given.charAt(0).toUpperCase() + given.slice(1);
}

export function prefixMetricKey(metricKey: string): string {
  return metricKey.startsWith(metricPrefix)
    ? metricKey
    : `${metricPrefix}${metricKey}`;
}

export function capitalizeAndPrefixKeys(rumData: RawRumData): RawRumData {
  return Object.fromEntries(
    Object.entries(rumData).map(([key, value]) => [
      prefixMetricKey(capitalizeFirstLetterAfterPrefix(key)),
      value,
    ]),
  );
}

export function containsOnlyWhitelistedMetrics(
  data: RumData | UserTimingData,
  metricType: METRIC_TYPE,
): boolean {
  if (!data?.rumMetrics) {
    return false;
  }
  const allowedMetricNames =
    metricType === METRIC_TYPE.rum ? rumArray : userTimingArray;
  return (
    data.rumMetrics.length !== 0 &&
    data.rumMetrics.every((metric) =>
      allowedMetricNames.includes(metric.metricName),
    )
  );
}

export function isRequiredDataCorrectlySet(
  data: RumData | UserTimingData,
  metricType: METRIC_TYPE,
): boolean {
  return (
    browser.includes(data.deviceBrowser) &&
    pageCluster.includes(data.pageCluster) &&
    deviceTypes.includes(data.rum_deviceType) &&
    containsOnlyWhitelistedMetrics(data, metricType)
  );
}

/**
 *
 *
 *
 *
 *
 *
 */
export function filterByAllowedNames(
  data: RawRumData,
  allowedValues: string[],
): RawRumData[] {
  return Object.entries(data)
    .filter(([metricName]) => allowedValues.includes(metricName))
    .map(([metricName, value]) => ({ metricName: metricName, value: value }));
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function normalizeBrowser(rawBrowser) {
  if (!rawBrowser) {
    return "undefined";
  }
  if (window.o_apps.runningInApp()) {
    return "App_WebView";
  } else {
    return rawBrowser.replace(" ", "");
  }
}
